import '../sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import SidebarSelectField from '../components/sidebar-select-field/sidebar-select-field';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../components/expandable-section/expandable-section';
import { useUnits } from '../../../hooks/useUnits';
import { useProperties } from '../../../hooks/useProperties';
import { useInvestors } from '../../../hooks/useInvestors';
import { IUnitOwner, createUnitOwner, deleteUnitOwner, updateUnitOwner } from '../../../services/unitOwnerService';
import DateSelect from '../../../components/date-select/date-select';
import { format } from 'date-fns';

interface AddUnitOwnerSidebarModalProps {
    isVisible: boolean;
    selectedOwner?: IUnitOwner;
    propertyId?: number;
    unitId?: number;
    investorId?: number;
    onClose: () => void;
    onAddUnitOwner: (unitOwner: IUnitOwner) => void;
    onUpdateUnitOwner?: (unitOwner: IUnitOwner) => void;
    onDeleteUnitOwner?: (owner_id: number) => void;
}

const AddUnitOwnerSidebarModal: React.FC<AddUnitOwnerSidebarModalProps> = ({ isVisible, selectedOwner, propertyId, unitId, investorId, onClose, onAddUnitOwner, onUpdateUnitOwner, onDeleteUnitOwner }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    const { properties, isReachingEnd: isReachingPropertiesEnd } = useProperties();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [investorsSearchQuery, setInvestorsSearchQuery] = useState<string>("");
    const [unitsSearchQuery, setUnitsSearchQuery] = useState<string>("");
    const [property, setProperty] = useState<string>(() => {
        if (propertyId) {
            return propertyId.toString();
        }
        if (properties.length > 0) {
            return properties[0].property_id.toString();
        }
        return "";
    });
    const [unit, setUnit] = useState<string>(unitId ? unitId.toString() : "");
    const [investor, setInvestor] = useState<string>(investorId ? investorId.toString() : "");
    const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
    const [shares, setShares] = useState<string>("");
    const [purchasePrice, setPurchasePrice] = useState<string>("");
    const [purchaseDateError, setPurchaseDateError] = useState<string>("");
    const [sharesError, setSharesError] = useState<string>("");
    const [purchasePriceError, setPurchasePriceError] = useState<string>("");
    const [error, setError] = useState<string>("");

    // Hooks
    const { investors, setSize: setInvestorsSize, isReachingEnd: isReachingInvestorsEnd } = useInvestors(null, investorsSearchQuery);
    const { units, setSize: setUnitsSize, isReachingEnd: isReachingUnitsEnd } = useUnits(property.length > 0 ? Number(property) : null, unitId ?? null, unitsSearchQuery);

    // Clean states
    useEffect(() => {
        if (selectedOwner) {
            setInvestor(selectedOwner.investor.investor_id.toString());
            setPurchaseDate(new Date(selectedOwner.purchase_date));
            setShares(selectedOwner.shares.toString());
            setPurchasePrice(selectedOwner.purchase_price.toString());
        }
    }, [selectedOwner]);

    useEffect(() => {
        if (!isVisible) {
            setShares("");
            setPurchasePrice("");
            setSharesError("");
            setPurchasePriceError("");

            setError("");
        }
    }, [isVisible]);

    // Create unit owner
    const onHandleCreateUnitOwner = useCallback(async () => {
        let isValid = true;

        // Check if purchase date is not empty
        if (!purchaseDate) {
            setPurchaseDateError(t("invalid_input.purchase_date_empty"));
            isValid = false;
        }

        // Check if the shares is not empty
        if (!shares.trim() || Number(shares) <= 0) {
            setSharesError(t("invalid_input.shares_empty"));
            isValid = false;
        }

        // Check if the purchase price is not empty
        if (!purchasePrice.trim()) {
            setPurchasePriceError(t("invalid_input.purchase_price_empty"));
            isValid = false;
        }

        if (isValid && property.length > 0 && unit.length > 0 && investor.length > 0) {
            try {
                setError("");
                setIsLoading(true);

                const createdUnitOwner = await createUnitOwner(
                    Number(unit),
                    Number(investor),
                    format(purchaseDate!, "yyyy-MM-dd"),
                    Number(shares),
                    Number(purchasePrice),
                );

                onAddUnitOwner(createdUnitOwner);

                showAlert("success", t("modals.unit_owner.add_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [purchaseDate, shares, purchasePrice, property, unit, investor, t]);

    const onHandleUpdateUnitOwner = useCallback(async () => {
        let isValid = true;

        // Check if purchase date is not empty
        if (!purchaseDate) {
            setPurchaseDateError(t("invalid_input.purchase_date_empty"));
            isValid = false;
        }

        // Check if the shares is not empty
        if (!shares.trim() || Number(shares) <= 0) {
            setSharesError(t("invalid_input.shares_empty"));
            isValid = false;
        }

        // Check if the purchase price is not empty
        if (!purchasePrice.trim()) {
            setPurchasePriceError(t("invalid_input.purchase_price_empty"));
            isValid = false;
        }

        if (isValid && selectedOwner && onUpdateUnitOwner) {
            try {
                setError("");
                setIsUpdating(true);

                const updatedUnitOwner = await updateUnitOwner(
                    selectedOwner.unit_owner_id,
                    format(purchaseDate!, "yyyy-MM-dd"),
                    Number(shares),
                    Number(purchasePrice),
                );

                showAlert("success", t("modals.unit_owner.update_success_message"));

                onUpdateUnitOwner(updatedUnitOwner);
            } catch (error) {
                console.log("Error while updating unit owner:", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [selectedOwner, purchaseDate, shares, purchasePrice, t]);

    const onHandleDelete = useCallback(async () => {
        if (!selectedOwner || !onDeleteUnitOwner) return;

        try {
            setIsDeleting(true);
            const deleted = await deleteUnitOwner(selectedOwner.unit_owner_id);
            if (deleted) {
                showAlert("success", t("modals.unit_owner.delete_success_message"));

                onDeleteUnitOwner(selectedOwner.unit_owner_id);
            }
        } catch (error) {
            console.log("Error while deleting unit owner: ", error);
        } finally {
            setIsDeleting(false);
        }
    }, [selectedOwner]);

    const onSearchUnit = useCallback((searchQuery: string, page: number) => {
        setUnitsSearchQuery(searchQuery);
        setUnitsSize(page);
    }, [setUnitsSize]);

    const onSearchInvestor = useCallback((searchQuery: string, page: number) => {
        setInvestorsSearchQuery(searchQuery);
        setInvestorsSize(page);
    }, [setInvestorsSize]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{selectedOwner ? t("modals.unit_owner.edit_title") : t("modals.unit_owner.add_title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <div className="row">
                            {!propertyId && (
                                <div className="col-12 col-md-6">
                                    <SidebarSelectField
                                        id="property"
                                        label={t("labels.property")}
                                        value={property}
                                        onChange={setProperty}
                                        options={properties.map(property => ({
                                            label: property.property_name,
                                            value: property.property_id.toString()
                                        }))}
                                        isEndReached={isReachingPropertiesEnd}
                                        required
                                        labelOnTop
                                    />
                                </div>
                            )}
                            {!unitId && (
                                <div className="col-12 col-md-6">
                                    {property && (
                                        <SidebarSelectField
                                            id="unit"
                                            label={t("labels.unit")}
                                            placeholder={t("placeholders.search")}
                                            value={unit}
                                            onChange={setUnit}
                                            options={units.map(unit => ({
                                                label: unit.unit_number,
                                                value: unit.unit_id.toString()
                                            }))}
                                            onSearch={onSearchUnit}
                                            isEndReached={isReachingUnitsEnd}
                                            required
                                            labelOnTop
                                            isSearchable
                                        />
                                    )}
                                </div>
                            )}
                            {!investorId && property && unit && !selectedOwner && (
                                <SidebarSelectField
                                    id="investor"
                                    label={t("labels.investor")}
                                    placeholder={t("placeholders.search")}
                                    value={investor}
                                    onChange={setInvestor}
                                    options={investors.map(investor => ({
                                        label: `${investor.first_name} ${investor.last_name}`,
                                        value: investor.investor_id.toString()
                                    }))}
                                    onSearch={onSearchInvestor}
                                    isEndReached={isReachingInvestorsEnd}
                                    required
                                    labelOnTop
                                    isSearchable
                                />
                            )}
                            {selectedOwner && selectedOwner.investor && (
                                <SidebarInputField
                                    id="investor-name"
                                    label={t("labels.investor")}
                                    value={`${selectedOwner.investor.first_name} ${selectedOwner.investor.last_name}`}
                                    onChange={() => { }}
                                    labelOnTop
                                    required
                                    disabled
                                />
                            )}
                        </div>

                        {/* GENERAL SECTION */}
                        <ExpandableSection
                            className="mt-4"
                            title={t("modals.unit_owner.general_title")}
                            isDefaultExpanded
                        >
                            <div className="row">
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="shares"
                                        type="number"
                                        label={t("labels.shares")}
                                        placeholder="50%"
                                        value={shares}
                                        errorMessage={sharesError}
                                        onClear={() => setShares("")}
                                        onChange={(value: string) => {
                                            let numericValue = parseFloat(value);

                                            if (numericValue < 0) {
                                                numericValue = 0;
                                            } else if (numericValue > 100) {
                                                numericValue = 100;
                                            }

                                            setShares(numericValue.toString());
                                            setSharesError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="purchase-price"
                                        type="number"
                                        label={t("labels.purchase_price")}
                                        placeholder="600,000.00"
                                        value={purchasePrice}
                                        errorMessage={purchasePriceError}
                                        onClear={() => setPurchasePrice("")}
                                        onChange={(value: string) => {
                                            let numericValue = parseFloat(value);

                                            if (numericValue < 0) {
                                                numericValue = 0;
                                            } else if (numericValue > 100000000) {
                                                numericValue = 100000000;
                                            }

                                            setPurchasePrice(numericValue.toString());
                                            setPurchasePriceError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12">
                                    <DateSelect
                                        id="purchase-date"
                                        label={t("labels.purchase_date")}
                                        placeholder={t("placeholders.select_date")}
                                        date={purchaseDate ?? null}
                                        onChange={(date) => {
                                            setPurchaseDate(date);
                                            setPurchaseDateError("");
                                        }}
                                        onClear={() => {
                                            setPurchaseDate(null);
                                            setPurchaseDateError("");
                                        }}
                                        errorMessage={purchaseDateError}
                                        labelOnTop
                                        required
                                    />
                                </div>
                            </div>
                        </ExpandableSection>
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        {selectedOwner ? (
                            <div className="d-flex">
                                <CustomButton
                                    className="w-100"
                                    title={t("modals.unit_owner.update_button")}
                                    isLoading={isUpdating}
                                    onClick={onHandleUpdateUnitOwner}
                                    disabled={isDeleting || isUpdating}
                                />
                                <CustomButton
                                    className="w-100 ms-3"
                                    color="red"
                                    title={t("modals.unit_owner.delete_button")}
                                    isLoading={isDeleting}
                                    onClick={onHandleDelete}
                                    disabled={isDeleting || isUpdating}
                                />
                            </div>
                        ) : (
                            <CustomButton
                                className="w-100"
                                title={t("modals.unit_owner.add_button")}
                                isLoading={isLoading}
                                onClick={onHandleCreateUnitOwner}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUnitOwnerSidebarModal;
