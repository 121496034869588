import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { IUnit } from "./unitService";

export enum PropertyManagementPackage {
  BASIC = "basic",
  PREMIUM = "premium",
}

export const formatPropertyManagementPackage = (
  pm_package: PropertyManagementPackage
) => {
  switch (pm_package) {
    case PropertyManagementPackage.BASIC:
      return "Basic";
    case PropertyManagementPackage.PREMIUM:
      return "Premium";
    default:
      return "-";
  }
};

export interface IPropertyManagementContract {
  contract_id: number;
  package: PropertyManagementPackage;
  start_date: string;
  sign_date?: string | null;
  terminated: boolean;
  unit?: IUnit;
  document?: IDocument | null;
  created_at: string;
  updated_at: string;
}

export const fetchPMContracts = async (
  url: string
): Promise<IPropertyManagementContract[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.contracts;
};

export const constructPMContractsUrl = (
  propertyId: number | null,
  search: string,
  page?: number,
  pageSize?: number,
  pm_package?: PropertyManagementPackage
): string => {
  let queryParams = new URLSearchParams();

  if (propertyId !== null) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (pm_package !== undefined && pm_package.length > 0) {
    queryParams.append("pm_package", pm_package);
  }

  return `${API_URL}/pm-contracts?${queryParams.toString()}`;
};

export const fetchPMContract = async (
  contract_id: number
): Promise<IPropertyManagementContract> => {
  const response = await fetch(`${API_URL}/pm-contracts/${contract_id}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const deletePMContract = async (
  contract_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/pm-contracts/${contract_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const createPMContract = async (
  unit_id: number,
  pm_package: PropertyManagementPackage,
  start_date: string,
  sign_date: string | null
): Promise<IPropertyManagementContract> => {
  const response = await fetch(`${API_URL}/pm-contracts`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      unit_id,
      pm_package,
      start_date,
      sign_date,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updatePMContract = async (
  contract_id: number,
  pm_package: PropertyManagementPackage,
  start_date: string,
  sign_date: string | null
): Promise<IPropertyManagementContract> => {
  const response = await fetch(`${API_URL}/pm-contracts/${contract_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pm_package,
      start_date,
      sign_date,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

// Upload Payment Document
export const uploadPMContractDocument = async (
  contract_id: number,
  file: File
): Promise<IDocument> => {
  const formData = new FormData();
  formData.append(`file`, file);

  const response = await fetch(`${API_URL}/pm-contracts/${contract_id}/document`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 201) return data.document;
  else throw new Error(data.message);
};

export const exportPMContractsCsv = async (
  pmContractIds: number[]
): Promise<Blob> => {
  const response = await fetch(`${API_URL}/pm-contracts/csv-export`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pmContractIds }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.blob();
};
