import './root.css';

import { useState, useEffect, useMemo, Suspense, useRef, useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import Topbar from '../components/topbar/topbar';
import useAlertStore from '../stores/alertStore';
import useViewportHeight from '../hooks/useViewportHeight';
import useAppStore from '../stores/appStore';
import { checkSession, logout } from '../services/authService';
import useConstructionUpdateStore from '../stores/constructionUpdateStore';
import useEmployeeStore from '../stores/employeeStore';
import useInvestorDetailStore from '../stores/investorDetailStore';
import useSearchStore from '../stores/searchStore';
import ScrollUpButton from '../components/scroll-up-button/scroll-up-button';
import * as Sentry from "@sentry/react";

const Root = () => {
    useViewportHeight();
    const location = useLocation();
    const contentAreaRef = useRef<HTMLDivElement>(null);

    // Stores
    const hideAlert = useAlertStore(state => state.hideAlert);
    const { isSidebarVisible, setSidebarVisible } = useAppStore();
    const { employee, reset: resetEmployeeStore } = useEmployeeStore();
    const resetInvestoreDetailStore = useInvestorDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);
    const setSearch = useSearchStore(state => state.setSearch);

    // States
    const [lastVisible, setLastVisible] = useState<number>(Date.now());

    // Set sentry user
    useEffect(() => {
        if (employee) {
            Sentry.setUser({
                id: employee.employee_id,
                email: employee.email,
                username: `${employee.first_name} ${employee.last_name}`
            });
        } else {
            Sentry.setUser(null);
        }
    }, [employee]);

    // Hide alert after navigation
    useEffect(() => {
        hideAlert();
        if (location.pathname === "/login") {
            setSidebarVisible(false);
        }
        setSearch("");
    }, [location, hideAlert, setSearch, setSidebarVisible]);

    const runCheckAuth = useCallback(async () => {
        try {
            await checkSession();
        } catch (error) {
            console.log('Error while checking user session: ', error);
            await logout();

            resetEmployeeStore();
            resetInvestoreDetailStore();
            resetConstructionUpdateStore();
        }
    }, [resetConstructionUpdateStore, resetEmployeeStore, resetInvestoreDetailStore]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setLastVisible(Date.now());
            } else if (document.visibilityState === 'visible') {
                const now = Date.now();
                const diff = now - lastVisible;

                if (diff > 1800000) { // 30 min
                    runCheckAuth();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [lastVisible, runCheckAuth]);

    useEffect(() => {
        runCheckAuth();
    }, [runCheckAuth]);

    useEffect(() => {
        if (contentAreaRef.current) {
            const isMobileView = () => window.innerWidth < 992;

            if (isMobileView()) {
                contentAreaRef.current.style.overflowY = isSidebarVisible ? 'hidden' : 'auto';
            }

            return () => {
                if (isMobileView()) {
                    document.body.style.overflow = 'auto';
                }
            };
        }
    }, [isSidebarVisible]);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const isLoginPage = useMemo(() => location.pathname === "/login", [location]);

    return (
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>}>
            <div>
                {isLoginPage ? (
                    <Outlet />
                ) : (
                    <>
                        <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                        <div id="detail">
                            <Topbar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                            <div id="content-area" ref={contentAreaRef}>
                                <Outlet />
                                <ScrollUpButton scrollableRef={contentAreaRef} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Suspense>
    );
};

export default Root;
