import { t } from "i18next";
import { UploadedFile } from "../../../components/file-upload/file-upload";
import { DocumentType, IDocument } from "../../../services/documentService";
import { useState } from "react";
import DeleteFileModal from "../../../modals/delete-file-modal/delete-file-modal";
import useEditPermission from "../../../hooks/useEditPermission";
import FileUploadSection from "../../../components/file-upload-section/file-upload-section";

interface InvestorDocumentSectionProps {
    files: UploadedFile[];
    internalFiles: UploadedFile[];
    documents: IDocument[];
    proofOfAddressFile: UploadedFile | null;
    powerOfAttorneyFile: UploadedFile | null;
    passportFiles: UploadedFile[];
    employeeRoleId: number | undefined;
    onDeleteInvestorDocument: (document: IDocument) => void;
    onUpdateInvestorFiles: (files: UploadedFile[]) => void;
    onUpdateInvestorInternalFiles: (files: UploadedFile[]) => void;
    onUpdatePassportFiles: (files: UploadedFile[]) => void;
    onProofOfAddressFileSelected: (selectedFile: UploadedFile[]) => void;
    removeProofOfAddressFile: () => void;
    onPowerOfAttorneyFileSelected: (selectedFile: UploadedFile[]) => void;
    removePowerOfAttorneyFile: () => void;
}

const InvestorDocumentSection: React.FC<InvestorDocumentSectionProps> = ({
    files,
    internalFiles,
    documents,
    proofOfAddressFile,
    powerOfAttorneyFile,
    passportFiles,
    employeeRoleId,
    onDeleteInvestorDocument,
    onUpdateInvestorFiles,
    onUpdateInvestorInternalFiles,
    onUpdatePassportFiles,
    onProofOfAddressFileSelected,
    removeProofOfAddressFile,
    onPowerOfAttorneyFileSelected,
    removePowerOfAttorneyFile
}) => {
    // Hooks
    const hasEditPermission = useEditPermission(employeeRoleId);

    // States
    const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState<IDocument | null>(null);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <FileUploadSection
                        fileUploadId="investor-upload-passport"
                        title={t("labels.passport")}
                        documents={documents.filter(document => document.document_type === DocumentType.PASSPORT)}
                        uploadedFiles={passportFiles}
                        onDeleteDocument={onDeleteInvestorDocument}
                        onUpdateUploadedFiles={onUpdatePassportFiles}
                        maxFilesToUpload={2}
                        hasEditPermission={hasEditPermission}
                        isDefaultExpanded
                        allowExpirationDate
                    />
                </div>

                <div className="col-12 col-md-6">
                    <FileUploadSection
                        fileUploadId="investor-upload-proof-of-address"
                        title={t("labels.proof_of_address")}
                        documents={documents.filter(document => document.document_type === DocumentType.PROOF_OF_ADDRESS)}
                        uploadedFiles={proofOfAddressFile ? [proofOfAddressFile] : []}
                        onDeleteDocument={onDeleteInvestorDocument}
                        onUpdateUploadedFiles={onProofOfAddressFileSelected}
                        maxFilesToUpload={1}
                        hasEditPermission={hasEditPermission}
                        isDefaultExpanded
                    />
                </div>

                <div className="col-12 col-md-6">
                    <FileUploadSection
                        fileUploadId="investor-power-of-attorney"
                        title={t("labels.power_of_attorney")}
                        documents={documents.filter(document => document.document_type === DocumentType.POWER_OF_ATTORNEY)}
                        uploadedFiles={powerOfAttorneyFile ? [powerOfAttorneyFile] : []}
                        onDeleteDocument={onDeleteInvestorDocument}
                        onUpdateUploadedFiles={onPowerOfAttorneyFileSelected}
                        maxFilesToUpload={1}
                        hasEditPermission={hasEditPermission}
                        isDefaultExpanded
                    />
                </div>

                {/* DOCUMENT SECTION */}
                {/*<div className="col-12 col-md-6">
                    <FileUploadSection
                        fileUploadId="investor-upload-other-documents"
                        title={t("components.document_section.available_documents_title", { number: documents.filter(document => document.document_type !== DocumentType.PASSPORT && document.document_type !== DocumentType.PROOF_OF_ADDRESS && !document.internal_document).length })}
                        subtitle={t("components.document_section.provide_documents_subtitle")}
                        documents={documents.filter(document => document.document_type !== DocumentType.PASSPORT && document.document_type !== DocumentType.PROOF_OF_ADDRESS && !document.internal_document)}
                        uploadedFiles={files}
                        onDeleteDocument={onDeleteInvestorDocument}
                        onUpdateUploadedFiles={onUpdateInvestorFiles}
                        hasEditPermission={hasEditPermission}
                        isDefaultExpanded
                    />
                </div>*/}

                {/* INTERNAL DOCUMENT SECTION */}
                {/*<div className="col-12 col-md-6">
                    <FileUploadSection
                        fileUploadId="investor-upload-internal-documents"
                        title={t("components.document_section.internal_documents_title", { number: documents.filter(document => document.document_type !== DocumentType.PASSPORT && document.document_type !== DocumentType.PROOF_OF_ADDRESS && document.internal_document).length })}
                        subtitle={t("components.document_section.internal_documents_subtitle")}
                        documents={documents.filter(document => document.document_type !== DocumentType.PASSPORT && document.document_type !== DocumentType.PROOF_OF_ADDRESS && document.internal_document)}
                        uploadedFiles={internalFiles}
                        onDeleteDocument={onDeleteInvestorDocument}
                        onUpdateUploadedFiles={onUpdateInvestorInternalFiles}
                        hasEditPermission={hasEditPermission}
                        isDefaultExpanded
                    />
                </div>*/}
            </div>

            {isDeleteFileModalVisible && (
                <DeleteFileModal
                    document_id={isDeleteFileModalVisible.document_id}
                    onClose={(deleted: boolean) => {
                        if (deleted) {
                            onDeleteInvestorDocument(isDeleteFileModalVisible);
                        }
                        setIsDeleteFileModalVisible(null);
                    }}
                />
            )}
        </>
    );
}

export default InvestorDocumentSection;
