import { BankDetailsForm } from "../routes/investor-detail-page/components/investor-bank-details-section/investor-bank-details-section";
import { API_URL } from "../utils/constants";
import { DocumentType, IDocument } from "./documentService";

export interface IBankDetail {
  details_id: number;
  iban: string;
  swift: string;
  bank_name: string;
  account_holder_name: string;
  account_number?: number | null;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
}

export const createBankDetail = async (
  investor_id: number,
  bank_details: BankDetailsForm
): Promise<IBankDetail> => {
  const response = await fetch(
    `${API_URL}/investors/${investor_id}/bank-details`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...bank_details,
      }),
    }
  );

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateBankDetail = async (
  details_id: number,
  bank_details: BankDetailsForm
): Promise<IBankDetail> => {
  const response = await fetch(`${API_URL}/bank-details/${details_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      iban: bank_details.iban,
      swift: bank_details.swift,
      bank_name: bank_details.bank_name,
      account_holder_name: bank_details.account_holder_name,
      account_number: bank_details.account_number,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const deleteBankDetail = async (
  details_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/bank-details/${details_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const uploadBankDetailDocuments = async (
  details_id: number,
  files: File[],
  document_type: DocumentType | null = null,
  valid_until_dates: (string | null)[] = []
): Promise<IDocument[]> => {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
    if (valid_until_dates[index]) {
      formData.append(
        `valid_until_${index}`,
        valid_until_dates[index] as string
      );
    }
  });

  const response = await fetch(
    `${API_URL}/bank-details/${details_id}/${document_type ?? "documents"}`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};
