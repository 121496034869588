export interface IDashboardData {
  dataNumbers: number[];
  totalInvestors: number;
  activeInvestors: number;
  loggedInInvestors: number;
  investorsWithoutDocuments: number;
  totalEmployees: number;
}

export const fetchDashboardData = async (url: string): Promise<IDashboardData> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};
