import './document-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentStatus, formatDocumentStatus, formatDocumentType, IDocument } from '../../services/documentService';
import { formatDate } from 'date-fns/format';
import ReviewDocumentModal from '../../modals/review-document-modal/review-document-modal';

interface DocumentTableProps {
    documents: IDocument[];
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const DocumentTable: React.FC<DocumentTableProps> = ({ documents, mutate }) => {
    const { t } = useTranslation();

    // States
    const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);

    return (
        <div>
            <div className="document-table-responsive">
                <table className="document-table">
                    <thead>
                        <tr>
                            <th>{t("document_table.investor_name_header")}</th>
                            <th>{t("document_table.document_type_header")}</th>
                            <th>{t("document_table.valid_until_header")}</th>
                            <th>{t("document_table.status_header")}</th>
                            <th>{t("document_table.reason_for_refusal_header")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map(document => (
                            <tr key={document.document_id} className={`${document.status.toLowerCase()}`} onClick={() => setSelectedDocument(document)}>
                                <td>{document.investor ? `${document.investor.first_name} ${document.investor.last_name}` : "-"}</td>
                                <td>{formatDocumentType(document.document_type, t)}</td>
                                <td>{document.valid_until ? formatDate(document.valid_until, "dd.MM.yyyy") : "-"}</td>
                                <td>
                                    <span className={`document-status-badge ${document.status.toLowerCase()}`}>
                                        {formatDocumentStatus(document.status, t)}
                                    </span>
                                </td>
                                <td>
                                    <span>{document.reason_for_refusal ?? "-"}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedDocument && (
                <ReviewDocumentModal
                    doc={selectedDocument}
                    onClose={() => setSelectedDocument(null)}
                    onAccepted={() => {
                        mutate(
                            (data: IDocument[][] | undefined) => {
                                if (!data) return [];
                                return data.map((page) =>
                                    page.map((item) =>
                                        item.document_id === selectedDocument.document_id ? { ...selectedDocument, status: DocumentStatus.ACCEPTED } : item
                                    )
                                );
                            },
                            false
                        );
                        setSelectedDocument(null);
                    }}
                    onDeclined={(reason: string) => {
                        mutate(
                            (data: IDocument[][] | undefined) => {
                                if (!data) return [];
                                return data.map((page) =>
                                    page.map((item) =>
                                        item.document_id === selectedDocument.document_id ? { ...selectedDocument, status: DocumentStatus.DECLINED, reason_for_refusal: reason } : item
                                    )
                                );
                            },
                            false
                        );
                        setSelectedDocument(null);
                    }}
                />
            )}
        </div>
    );
};

export default DocumentTable;
