import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import usePMContractFilterStore from '../../../../stores/pmContractFilterStore';
import { PropertyManagementPackage } from '../../../../services/pmContractService';

interface PMContractFiltersProps {
    onFilterUpdated: () => void;
}

const PMContractFilters: React.FC<PMContractFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    const pmPackageOptions: SidebarSelectFieldOptions[] = Object.values(PropertyManagementPackage).map((purpose) => ({
        label: purpose.replace(/-/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),
        value: purpose,
    }));

    // Zustand store
    const { pm_package, isExpanded, setFilters, setIsExpanded } = usePMContractFilterStore();

    // Local state for filters
    const [localPackage, setLocalPackage] = useState<PropertyManagementPackage | null>(null);

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localPackage !== pm_package;

        setFiltersChanged(hasChanges);
    }, [localPackage, pm_package]);

    const handleApplyFilters = () => {
        setFilters(localPackage);
        onFilterUpdated();
    };

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* PM Package Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="pm-contract-filter-package-selection"
                        label={t("labels.pm_package")}
                        placeholder={t("placeholders.select")}
                        value={localPackage ?? undefined}
                        onChange={(value: string) => {
                            const mappedValue = value as PropertyManagementPackage;
                            setLocalPackage(mappedValue);
                        }}
                        options={pmPackageOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default PMContractFilters;
