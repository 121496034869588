import './investor-bank-details-section.css';

import React, { useCallback, useEffect, useState } from 'react';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import { useTranslation } from 'react-i18next';
import { IBankDetail, deleteBankDetail } from '../../../../services/bankDetailService';
import { IoTrashBinOutline } from 'react-icons/io5';
import { EmployeeRole } from '../../../../services/employeeService';
import useEmployeeStore from '../../../../stores/employeeStore';
import DeleteConfirmationModal from '../../../../modals/delete-confirmation-modal/delete-confirmation-modal';
import useEditPermission from '../../../../hooks/useEditPermission';
import { IDocument } from '../../../../services/documentService';

export interface BankDetailsForm {
    iban: string;
    swift: string;
    bank_name: string;
    account_holder_name: string;
    account_number?: number | null;
}

interface Errors {
    iban: string;
    swift: string;
    bank_name: string;
    account_holder_name: string;
    [key: string]: string;
}

interface InvestorBankDetailsSectionProps {
    details_id: number | null,
    bank_details: IBankDetail | null,
    onUpdateBankDetailsForm: (bankDetailsForm: BankDetailsForm) => void;
    onBankDetailsDeleted: () => void;
    onDeleteDocument: (document: IDocument) => void;
}

const InvestorBankDetailsSection: React.FC<InvestorBankDetailsSectionProps> = ({
    details_id,
    bank_details,
    onUpdateBankDetailsForm,
    onBankDetailsDeleted,
    onDeleteDocument,
}) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [bankDetailsForm, setBankDetailsForm] = useState<BankDetailsForm>({
        iban: '',
        swift: '',
        bank_name: '',
        account_holder_name: '',
        account_number: null,
    });
    const [errors, setErrors] = useState<Errors>({
        iban: '',
        swift: '',
        bank_name: '',
        account_holder_name: '',
    });

    useEffect(() => {
        if (bank_details) {
            setBankDetailsForm({
                iban: bank_details.iban,
                swift: bank_details.swift,
                bank_name: bank_details.bank_name,
                account_holder_name: bank_details.account_holder_name,
                account_number: bank_details.account_number,
            });
        } else {
            setBankDetailsForm({
                iban: '',
                swift: '',
                bank_name: '',
                account_holder_name: '',
                account_number: null,
            });
        }
    }, [bank_details]);

    useEffect(() => {
        onUpdateBankDetailsForm(bankDetailsForm);
    }, [bankDetailsForm, onUpdateBankDetailsForm]);

    // Functions
    const handleChange = (name: keyof BankDetailsForm) => (value: string) => {
        const error = !value && ['iban', 'swift', 'bank_name', 'account_holder_name'].includes(name) ? 'This field is required' : '';
        setBankDetailsForm(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const onDeleteBankDetail = useCallback(async () => {
        if (details_id) {
            try {
                setIsDeleteModalVisible(false);
                const deleted = await deleteBankDetail(details_id);
                if (deleted) {
                    onBankDetailsDeleted();
                }
            } catch (error) {
                console.log('Error while deleting investor bank details:', error);
            }
        }
    }, [details_id, onBankDetailsDeleted]);

    return (
        <ExpandableSection
            className="bank-detail-section"
            title={t("pages.investor_detail.bank_details_information_title")}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                {details_id && hasEditPermission && (
                    <div className="delete-button mb-4 text-danger" onClick={() => setIsDeleteModalVisible(true)}>
                        <IoTrashBinOutline className="delete-button-icon" />
                        <span>{t("buttons.delete")}</span>
                    </div>
                )}
                <div className="row">
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="iban"
                            label={t("labels.bank_details.iban")}
                            value={bankDetailsForm.iban}
                            onChange={handleChange('iban')}
                            onClear={() => handleChange('iban')("")}
                            errorMessage={errors.iban}
                            placeholder={t("placeholders.bank_details.iban")}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="swift"
                            label={t("labels.bank_details.swift")}
                            value={bankDetailsForm.swift}
                            onChange={handleChange('swift')}
                            onClear={() => handleChange('swift')("")}
                            errorMessage={errors.swift}
                            placeholder={t("placeholders.bank_details.swift")}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="bank_name"
                            label={t("labels.bank_details.bank_name")}
                            value={bankDetailsForm.bank_name}
                            onChange={handleChange('bank_name')}
                            onClear={() => handleChange('bank_name')("")}
                            errorMessage={errors.bank_name}
                            placeholder={t("placeholders.bank_details.bank_name")}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="account_holder_name"
                            label={t("labels.bank_details.account_holder_name")}
                            value={bankDetailsForm.account_holder_name}
                            onChange={handleChange('account_holder_name')}
                            onClear={() => handleChange('account_holder_name')("")}
                            errorMessage={errors.account_holder_name}
                            placeholder={t("placeholders.bank_details.account_holder_name")}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="account_number"
                            label={t("labels.bank_details.account_number")}
                            type="text"
                            value={bankDetailsForm.account_number?.toString() || ''}
                            onChange={handleChange('account_number')}
                            onClear={() => handleChange('account_number')("")}
                            placeholder={t("placeholders.bank_details.account_number")}
                        />
                    </div>
                </div>
            </div>

            {/* DELETE CONFIRMATION MODAL */}
            {isDeleteModalVisible && hasEditPermission && (
                <DeleteConfirmationModal
                    title={t("pages.investor_detail.bank_details_section.delete_modal.title")}
                    message={t("pages.investor_detail.bank_details_section.delete_modal.message")}
                    onClose={() => setIsDeleteModalVisible(false)}
                    onDelete={onDeleteBankDetail}
                />
            )}
        </ExpandableSection>
    );
};

export default InvestorBankDetailsSection;
