// DashboardPage.tsx
import BarChart from "../components/bar-chart/bar-chart";
import PieChart from "../components/pie-chart/pie-chart";
import useEmployeeStore from "../stores/employeeStore";
import { useEffect, useMemo } from "react";
import useAlertStore from "../stores/alertStore";
import { useTranslation } from "react-i18next";
import { interpolateColor } from "../utils/helpers";
import { useDashboard } from "../hooks/useDashboard";
import usePageTitle from "../hooks/usePageTitle";
import Lottie from "lottie-react";
import LoadingAnim from "../assets/anims/loading.-anim.json";

const DashboardPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.dashboard.page_title"));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { employee } = useEmployeeStore();

    // Hooks
    const { dashboardData, isLoading, error } = useDashboard();

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const ratio = useMemo(() => {
        if (!dashboardData) return 0;
        return dashboardData.totalInvestors > 0 ? dashboardData.investorsWithoutDocuments / dashboardData.totalInvestors : 0;
    }, [dashboardData]);

    const investorsWithoutDocumentsColor = useMemo(() => interpolateColor("#e74c3c", "#76CA66", 1 - ratio), [ratio]);

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.dashboard.title", { name: employee?.first_name })}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.dashboard.subtitle")}</p>
                </div>
            </div>

            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                dashboardData && (
                    <div className="row mt-4 gy-2">
                        <div className="col-12 col-md-4 col-xl-3">
                            <div className="white-card">
                                <p>{t("pages.dashboard.active_investors")}</p>
                                <PieChart
                                    colors={['#76CA66', '#D9DDE7']}
                                    total={dashboardData.totalInvestors}
                                    active={dashboardData.activeInvestors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                            <div className="white-card">
                                <p>{t("pages.dashboard.investors_without_documents")}</p>
                                <PieChart
                                    colors={[investorsWithoutDocumentsColor, '#D9DDE7']}
                                    total={dashboardData.totalInvestors}
                                    active={dashboardData.investorsWithoutDocuments}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                            <div className="white-card">
                                <p>{t("pages.dashboard.logged_in_investors")}</p>
                                <PieChart
                                    colors={['#76CA66', '#D9DDE7']}
                                    total={dashboardData.totalInvestors}
                                    active={dashboardData.loggedInInvestors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-3">
                            <div className="white-card">
                                <p>{t("pages.dashboard.total_employees")}</p>
                                <PieChart
                                    colors={['#76CA66', '#D9DDE7']}
                                    total={dashboardData.totalEmployees}
                                    active={dashboardData.totalEmployees}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-xl-6">
                            <div className="white-card">
                                <p>{t("pages.dashboard.investor_growth")}</p >
                                <BarChart dataNumbers={dashboardData.dataNumbers} />
                            </div >
                        </div >
                    </div >
                )
            )}
        </div>
    );
};

export default DashboardPage;
