import useSWRInfinite from 'swr/infinite';
import { constructPMContractsUrl, fetchPMContracts, IPropertyManagementContract, PropertyManagementPackage } from '../services/pmContractService';

const PAGE_SIZE = 20;

export const usePMContracts = (
    propertyId: number | null,
    search: string,
    pm_package?: PropertyManagementPackage
) => {
    const fetcher = (url: string) => fetchPMContracts(url);

    const getKey = (pageIndex: number, previousPageData: IPropertyManagementContract[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructPMContractsUrl(propertyId, search, pageIndex + 1, PAGE_SIZE, pm_package);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IPropertyManagementContract[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        contracts: data ? ([] as IPropertyManagementContract[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
