import { TFunction } from "i18next";
import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { DocumentType, IDocument } from "./documentService";
import { ILease } from "./leaseService";
import { IParkingSpace } from "./parkingSpaceService";
import { IProperty } from "./propertyService";
import { IUnitOwner } from "./unitOwnerService";
import { IPropertyManagementContract } from "./pmContractService";

export enum UnitPurposeType {
  INVESTMENT = "investment",
  SELF_INTEREST = "self-interest",
}

export const formatUnitPurposeType = (
  purpose: UnitPurposeType,
  t: TFunction
) => {
  switch (purpose) {
    case UnitPurposeType.INVESTMENT:
      return t("labels.purpose_types.investment");
    case UnitPurposeType.SELF_INTEREST:
      return t("labels.purpose_types.self_interest");
    default:
      return "-";
  }
};

export enum UnitSoldBy {
  DG_HOMES = "dg-homes",
  METRICAL_DEVELOPMENTS = "metrical-developments",
}

export const formatSoldBy = (soldBy: UnitSoldBy) => {
  switch (soldBy) {
    case UnitSoldBy.DG_HOMES:
      return "DG Homes";
    case UnitSoldBy.METRICAL_DEVELOPMENTS:
      return "Metrical Developments";
    default:
      return "-";
  }
};

export enum UnitStatus {
  AVAILABLE = "available",
}

export const formatUnitStatus = (status: UnitStatus, t: TFunction) => {
  switch (status) {
    case UnitStatus.AVAILABLE:
      return t("labels.states.available");
    default:
      return "-";
  }
};

export interface IPicture {
  picture_id: number;
  picture_path: string;
  created_at: string;
}

export interface IUnitAccount {
  id: number;
  zoho_id: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

export interface IUnit {
  unit_id: number;
  property: IProperty;
  unit_number: string;
  square_feet: number;
  bedroom: number;
  bathroom: number;
  floor: string;
  under_renovation: boolean;
  under_dispute: boolean;
  furnished: boolean;
  dewa?: string | null;
  empower?: string | null;
  municipality_no?: string | null;
  gas_no?: string | null;
  minimum_annual_rent?: number | null;
  purpose_type: UnitPurposeType;
  sold_by: UnitSoldBy;
  owners: IUnitOwner[];
  status: UnitStatus;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
  pictures: IPicture[];
  parking_spaces: IParkingSpace[];
  unit_account: IUnitAccount;
  pm_contract?: IPropertyManagementContract | null;
}

export const fetchUnits = async (url: string): Promise<IUnit[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.units;
};

export enum UnitSortBy {
  UNIT_NUMBER = "unit_number",
  BEDROOM = "bedroom",
  BATHROOM = "bathroom",
  FLOOR = "floor",
  SQUARE_FEET = "square_feet",
  MINIMUM_ANNUAL_RENT = "minimum_annual_rent",
}

export const constructUnitsUrl = (
  propertyId: number | null,
  unitId: number | null,
  search: string,
  page?: number,
  pageSize?: number,
  bedrooms?: string,
  bathrooms?: string,
  minimum_annual_rent?: string,
  sold_by?: string,
  status?: string,
  purpose?: string,
  floor?: string,
  sortBy?: UnitSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (propertyId !== null) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (unitId !== null) {
    queryParams.append("unitId", unitId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (bedrooms !== undefined && bedrooms.length > 0) {
    queryParams.append("bedrooms", bedrooms);
  }
  if (bathrooms !== undefined && bathrooms.length > 0) {
    queryParams.append("bathrooms", bathrooms);
  }
  if (minimum_annual_rent !== undefined && minimum_annual_rent.length > 0) {
    queryParams.append("minimum_annual_rent", minimum_annual_rent);
  }
  if (sold_by !== undefined && sold_by.length > 0) {
    queryParams.append("sold_by", sold_by);
  }
  if (status !== undefined && status.length > 0) {
    queryParams.append("status", status);
  }
  if (purpose !== undefined && purpose.length > 0) {
    queryParams.append("purpose", purpose);
  }
  if (floor !== undefined && floor.length > 0) {
    queryParams.append("floor", floor);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/units?${queryParams.toString()}`;
};

export const fetchUnit = async (unit_id: number): Promise<IUnit> => {
  const response = await fetch(`${API_URL}/units/${unit_id}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const deleteUnit = async (unit_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/units/${unit_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const createUnit = async (
  property_id: number,
  unit_number: string,
  square_feet: number,
  bedroom: number,
  bathroom: number,
  floor: string,
  under_renovation: boolean,
  under_dispute: boolean,
  furnished: boolean,
  dewa: string,
  empower: string,
  municipality_no: string,
  gas_no: string,
  purpose_type: string,
  sold_by: string,
  minimum_annual_rent?: number
): Promise<IUnit> => {
  const response = await fetch(`${API_URL}/units`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      property_id,
      unit_number,
      square_feet,
      bedroom,
      bathroom,
      floor,
      under_renovation,
      under_dispute,
      furnished,
      dewa,
      empower,
      municipality_no,
      gas_no,
      minimum_annual_rent: minimum_annual_rent ?? null,
      purpose_type,
      sold_by,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateUnit = async (unit: IUnit): Promise<IUnit> => {
  const response = await fetch(`${API_URL}/units/${unit.unit_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(unit),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const uploadUnitDocuments = async (
  unit_id: number,
  files: File[],
  document_type: DocumentType | null = null,
  valid_until_dates: (string | null)[] = [],
  internal_document_list: string[] = []
): Promise<IDocument[]> => {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
    if (valid_until_dates[index]) {
      formData.append(
        `valid_until_${index}`,
        valid_until_dates[index] as string
      );
    }
    if (internal_document_list[index]) {
      formData.append(
        `internal_document_${index}`,
        internal_document_list[index]
      );
    }
  });

  const response = await fetch(
    `${API_URL}/units/${unit_id}/${document_type ?? "documents"}`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};

export const uploadUnitPictures = async (
  unit_id: number,
  pictures: File[]
): Promise<IPicture[]> => {
  const formData = new FormData();

  // Append the picture file to the form data if provided
  pictures.forEach((file, index) => {
    formData.append(`file_${index}`, file);
  });

  const response = await fetch(`${API_URL}/units/${unit_id}/pictures`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 201) return data.pictures;
  else throw new Error(data.message);
};

export const deleteUnitPicture = async (
  unit_id: number,
  picture_id: number
): Promise<boolean> => {
  const response = await fetch(
    `${API_URL}/units/${unit_id}/pictures/${picture_id}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  );

  return response.status === 200;
};

export enum IUnitLeaseSearchType {
  ACTIVE = "active",
  UPCOMING = "upcoming",
}

export const fetchUnitLease = async (
  unit_id: number,
  type?: IUnitLeaseSearchType | undefined
): Promise<ILease | null> => {
  const url = new URL(`${API_URL}/units/${unit_id}/leases`);

  if (type) {
    url.searchParams.append("type", type);
  }

  const response = await fetch(url.toString(), {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (response.status === 200)
    return data.leases.length > 0 ? data.leases[0] : null;
  else throw new Error(data.message);
};

export const uploadUnitCsvFile = async (csv: File): Promise<void> => {
  const formData = new FormData();
  formData.append(`csv`, csv);

  const response = await fetch(`${API_URL}/units/csv-import`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  if (response.status === 201) return Promise.resolve();
  const data = await response.json();
  throw new Error(data.message);
};

export const exportUnitCsv = async (unitIds: number[]): Promise<Blob> => {
  const response = await fetch(`${API_URL}/units/csv-export`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ unitIds }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.blob();
};
