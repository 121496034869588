import "./unit-utility-section.css";

import { t } from "i18next";
import ExpandableSection from "../../../../components/expandable-section/expandable-section";
import CustomInputField from "../../../../components/custom-input-field/custom-input-field";
import { IUnit } from "../../../../services/unitService";

interface UnitUtilitySectionProps {
    employeeRoleId: number | undefined;
    unit: IUnit;
    onUpdateUnit: (unit: IUnit) => void;
}

const UnitUtilitySection: React.FC<UnitUtilitySectionProps> = ({ employeeRoleId, unit, onUpdateUnit }) => {
    return (
        <ExpandableSection
            className="unit-utility-section"
            title={t("pages.unit_detail.utility_information_title")}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="dewa-number"
                            type="text"
                            label={t("labels.dewa_number")}
                            placeholder="E92K2712"
                            value={unit.dewa?.toString() ?? ""}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, dewa: text });
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, dewa: null });
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="empower-number"
                            type="text"
                            label={t("labels.empower_number")}
                            placeholder="E92K2712"
                            value={unit.empower?.toString() ?? ""}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, empower: text });
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, empower: null });
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="gas-number"
                            type="text"
                            label={t("labels.gas_number")}
                            placeholder="6372621"
                            value={unit.gas_no?.toString() ?? ""}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, gas_no: text });
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, gas_no: null });
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <CustomInputField
                            id="municipality-number"
                            type="text"
                            label={t("labels.municipality_number")}
                            placeholder="92736182"
                            value={unit.municipality_no?.toString() ?? ""}
                            onChange={(text: string) => {
                                onUpdateUnit({ ...unit, municipality_no: text });
                            }}
                            onClear={() => {
                                onUpdateUnit({ ...unit, municipality_no: null });
                            }}
                        />
                    </div>
                </div>
            </div>
        </ExpandableSection>
    );
}

export default UnitUtilitySection;
