import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { PropertyManagementPackage } from "../services/pmContractService";

interface PMContractFilterState {
  pm_package: PropertyManagementPackage | null;
  selectedProperty: number | null;
  isExpanded: boolean;
  setFilters: (pm_package: PropertyManagementPackage | null) => void;
  setSelectedProperty: (propertyId: number | null) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const usePMContractFilterStore = create<PMContractFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        pm_package: null,
        selectedProperty: null,
        isExpanded: false,
        setFilters: (pm_package) =>
          set({
            pm_package,
          }),
        setSelectedProperty: (selectedProperty) => set({ selectedProperty }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "pm-contract-filter-storage",
      }
    )
  )
);

export default usePMContractFilterStore;
